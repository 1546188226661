import React, {memo} from "react";
// local
import {IconNotificationCircle} from "components/icons";
import {CustomTooltip} from "components/CustomTooltip";
import {color} from "constants/color.consts";

export const InfoTooltip = memo(
  ({
    tooltip,
    noTranslate,
    centerText,
    addClass,
    addWrapperClass,
    addIconWrapperClass,
    iconColor,
  }) => {
    return (
      <CustomTooltip
        text={tooltip}
        centerText={centerText}
        noTranslate={noTranslate}
        addClass={addWrapperClass}
      >
        <div style={{height: "20px", display: "flex"}} className={addIconWrapperClass}>
          <IconNotificationCircle color={iconColor || color.black} addClass={addClass} />
        </div>
      </CustomTooltip>
    );
  }
);
