// ======================================
// Working with data on the seller side (MAKE OFFERS). Data
// ======================================

import {makeAutoObservable} from "mobx";
//local
import axiosInstance from "../axios/index";
import {directionReplacement} from "helpers/helper";
import RequestSection from "pages/RequestsToUs/components/SingleRequest/sections/RequestSection";
import OurOfferSection from "pages/RequestsToUs/components/SingleRequest/sections/OurOfferSection";
import ContractSection from "pages/RequestsToUs/components/SingleRequest/sections/ContractSection";
import MessagesSection from "pages/RequestsToUs/components/SingleRequest/sections/MessagesSection";
import {
  defaultCreatedFieldSearch,
  defaultClosedAtFieldSearch,
} from "constants/user.consts";
import {emptyPublicRequest} from "constants/seller.consts";

class SellerStore {
  // STATE. Observable and computed part
  // ****** COMMON ******
  btnLoading = false; // loading status for buttons
  loading = true; // loading status for parts of the page

  // ****** REQUESTS ******
  unreadRequests = 0; // counter for badge with number of unreaded requests
  requests = []; // requests
  request = {}; // data of the current (viewed) request
  activeRequestTab = 0; // number of the active tab when viewing a specific request
  // array of tabs when viewing a specific request
  get requestToUsTabs() {
    return [
      {
        title: "REQUEST",
        content: RequestSection,
        id: 0,
        hash: "#",
        visible: true,
      },
      {
        title: "OUR_OFFER",
        content: OurOfferSection,
        id: 1,
        hash: "#",
        visible: true,
        disabled: !this.isOffer,
      },
      {
        title: "CONTRACT",
        content: ContractSection,
        id: 2,
        hash: "#",
        visible: true,
        disabled: !this.isContract,
      },
      {
        title: "CHAT",
        content: MessagesSection,
        id: 3,
        hash: "#",
        visible: true,
        class: "messages-tab",
      },
    ];
  }
  // filter parameters for requests list
  requestFilterParams = defaultCreatedFieldSearch;

  // ****** OFFERS ******
  offers = []; // offers
  currentOfferId = ""; // id of the current (viewed) offer
  offerLoading = false; // loading status for offers page
  get offerFiles() {
    return this.request?.Offer?.OfferFiles?.filter((item) => !!item.File);
  }
  // filter parameters for offers list
  offerFilterParams = defaultCreatedFieldSearch;
  get isOffer() {
    return !!this.request?.Offer;
  }

  // ****** CONTRACTS ******
  contracts = []; // contracts
  currentContract = null; // data of the current (viewed) contract
  isContract = false; // status showing whether the request has a contract
  unsignedSellerContracts = 0; // counter for badge with number unsigned contracts on seller side
  // returns the status of the seller's signature in the current contract
  get contractOwnPersons() {
    return this.currentContract?.SellerSignatories;
  }
  // status for a fully signed own side contract
  get isFinishedOwnSignatures() {
    return (
      !!this.contractOwnPersons?.length &&
      this.contractOwnPersons?.every((user) => !!user.SellerSignId)
    );
  }
  // returns the status of the buyer's signature in the current contract
  get contractBuyerPersons() {
    return this.currentContract?.BuyerSignatories.filter((user) => !!user.BuyerSignId);
  }
  // status for a fully signed seller side contract
  get isFinishedOtherSignatures() {
    return (
      !!this.currentContract?.BuyerSignatories?.length &&
      this.currentContract?.BuyerSignatories?.every((user) => !!user.BuyerSignId)
    );
  }
  // status for a fully signed (finished) contract
  get isFinishedContract() {
    return this.currentContract?.isSigned;
  }

  // filter parameters for contracts list
  contractFilterParams = defaultCreatedFieldSearch;

  // ****** PUBLIC RFPS ******
  // filter parameters for public rfps list
  publicRfpsFilterParams = defaultClosedAtFieldSearch;

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS
  // ****** WORKING WITH REQUESTS (PROPOSALS FROM BUYER SIDE) ******

  // getting all requests
  async getRequests(page) {
    this.loading = true;
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.requestFilterParams);
    const params = {...this.requestFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get("rfp/seller/search", {params});
      this.loading = false;
      // need to separate fully signed contracts
      const dataWithoutFinishedContracts = data.filter(
        (item) => !item.Offer?.Contract?.id
      );
      this.requests = dataWithoutFinishedContracts;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // getting one specific request
  async getRequest(id, notLoading) {
    this.loading = !notLoading;
    if (!id) return (this.request = {});
    return axiosInstance
      .get(`rfp/seller/${id}`)
      .then(({data}) => {
        this.setOfferId(data.Offer?.id);
        this.request = data;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // setting updated information on RFP after editing in store
  updateRequestInfo(request) {
    this.request = request;
  }

  // updating a specific request
  async updateRequest(value) {
    return axiosInstance
      .put(`rfp/seller/${this.request.id}`, value)
      .then(({data}) => this.getRequest(data.id, true));
  }

  // setting number for active tab of open request
  setActiveRequestTab(value) {
    this.activeRequestTab = value;
  }

  // setting filters for the requests
  setRequestFilterParams(values) {
    this.requestFilterParams = values;
  }

  // ****** WORKING WITH OFFERS ******

  // getting list of offers
  async getOffers(page) {
    this.loading = true;
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.offerFilterParams);
    const params = {...this.offerFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get("rfp/seller/offer/search", {params});
      this.loading = false;
      this.offers = data;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // creating offer
  createOurOffer(values) {
    return axiosInstance
      .post("rfp/seller/offer/create", this.generateOfferData(values))
      .then(({data}) => {
        this.setOfferId(data.id);
        this.setOffer(data);
        return data;
      });
  }

  // getting the data of a specific offer
  getOffer(id) {
    this.offerLoading = true;
    return axiosInstance
      .get(`rfp/seller/offer/${id}`)
      .then(({data}) => {
        this.setOffer(data);
        return data;
      })
      .finally(() => (this.offerLoading = false));
  }

  // preparing data of specific offer for updateOurOffer endpoint
  generateOfferData(value) {
    const documentsIdArray = this.request.Offer?.OfferFiles?.filter(
      (item) => !!item.File
    ).map((item) => item.File.id);
    return {
      ...value,
      ProposalId: this.request.id ?? null,
      RequestId: this.request.Request?.id,
      documents: documentsIdArray || [],
    };
  }

  // updating specific offer
  updateOurOffer(values) {
    const offerId = this.request.Offer.id;
    return axiosInstance
      .post(`rfp/seller/offer/${offerId}`, this.generateOfferData(values))
      .then(({data}) => this.setOffer(data));
  }

  // setting information about offer for current request in store
  setOffer(value) {
    this.request = {
      ...this.request,
      Offer: {...this.request.Offer, ...value},
    };
  }

  // updating list of offer documents in specific request in store after uploading file
  updateOfferDocuments(file) {
    const offerFiles = file
      ? !this.request.Offer?.OfferFiles
        ? [!!file.url ? {id: "newFile", File: file} : file]
        : [
            ...this.request.Offer?.OfferFiles,
            !!file.url ? {id: "newFile", File: file} : file,
          ]
      : null;

    const offer = !this.request.Offer
      ? {OfferFiles: offerFiles}
      : {
          ...this.request.Offer,
          OfferFiles: offerFiles,
        };
    this.request = {
      ...this.request,
      Offer: offer,
    };
  }

  // uploading specific file for offer
  async uploadSellerFile(file, notUpdateDocuments) {
    return axiosInstance.post(`rfp/seller/upload`, file).then(({data}) => {
      !notUpdateDocuments && data && this.updateOfferDocuments(data);
      return data;
    });
  }

  // removing files from offer and record the results to the store
  removeFromSellerFiles(id) {
    this.request = {
      ...this.request,
      Offer: {
        ...this.request.Offer,
        OfferFiles: id
          ? this.request.Offer.OfferFiles.filter((item) => item?.id !== id)
          : [],
      },
    };
  }

  // setting id of current (viewed) offer
  setOfferId(id) {
    this.currentOfferId = id;
  }

  // setting filters for the offers
  setOfferFilterParams(values) {
    this.offerFilterParams = values;
  }

  // ****** WORKING WITH CONTRACTS ******

  // getting list of contracts (finished)
  async getContracts(page) {
    this.loading = true;
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.contractFilterParams);
    const params = {...this.contractFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get("rfp/seller/contract/search", {params});
      this.loading = false;
      this.contracts = data;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // getting one specific contract
  async getContract(id, timer) {
    this.loading = !timer;
    try {
      const {data} = await axiosInstance.get(`rfp/seller/contract/${id}`);
      this.currentContract = data;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  // setting the status if there is a created contract in the request
  setContractStatus(status) {
    this.isContract = status;
  }

  // setting filters for the contracts
  setContractFilterParams(values) {
    this.contractFilterParams = values;
  }

  // adding one signatory into the specific contract
  async addSignatoryOnContract(contractId, UserId) {
    try {
      await axiosInstance.post(`rfp/seller/contract/${contractId}/signatory`, {UserId});
      await this.getContract(contractId);
    } catch (e) {
      throw e;
    }
  }

  // remove one signatory into the specific contract
  async removeSignatoryOnContract(contractId, UserId) {
    try {
      await axiosInstance.delete(`rfp/seller/contract/${contractId}/signatory/${UserId}`);
      await this.getContract(contractId);
    } catch (e) {
      throw e;
    }
  }

  // getting contract pdf-file with watermarks
  async downloadPdfWatermarkContract(id, contractUrl) {
    try {
      const {data} = await axiosInstance.post(
        `/rfp/seller/contract/${id}/pdf-footer`,
        {contractUrl},
        {responseType: "blob"}
      );
      return data;
    } catch (e) {
      throw e;
    }
  }

  clearContract() {
    this.currentContract = null;
  }

  // ****** WORKING WITH NOTIFICATIONS ******

  // getting number of unreaded requests
  async getUnreadRequests() {
    try {
      const {data} = await axiosInstance.get("rfp/seller/get-unread-requests");
      this.unreadRequests = data;
    } catch (e) {
      throw e;
    }
  }

  // getting number unsigned contracts on seller side
  async getUnsignedSellerContracts(page) {
    try {
      const {data} = await axiosInstance.get("rfp/seller/get-unsigned-seller-contracts");

      this.unsignedSellerContracts = data;
    } catch (e) {
      throw e;
    }
  }

  // ****** WORKING WITH MESSAGES ******

  // getting all messages for specific RFP
  async getRfpMessages({rfpId}) {
    try {
      const {data} = await axiosInstance.get(`rfp/seller/${rfpId}/message/search`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  // creating message for specific RFP
  async createRfpMessage({rfpId, message, fileId}) {
    try {
      const {data} = await axiosInstance.post(`rfp/seller/${rfpId}/message`, {
        message,
        fileId,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  // updating message for specific RFP
  async updateRfpMessage({id, rfpId, message, fileId}) {
    try {
      const {data} = await axiosInstance.put(`rfp/seller/${rfpId}/message/${id}`, {
        message,
        fileId,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  // deleting message for specific organization in specific RFP
  async deleteRfpMessage({id, rfpId, message}) {
    try {
      const {data} = await axiosInstance.delete(`rfp/seller/${rfpId}/message/${id}`, {
        message,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  // uploading file for message
  messageUploadFile({rfpId, file}) {
    const data = new FormData();
    data.append("file", file.file.originFileObj);
    return axiosInstance
      .post(`rfp/seller/${rfpId}/message/upload`, data)
      .then(({data}) => {
        return data;
      });
  }

  // ****** WORKING WITH PUBLIC RFPS ******

  // getting list of public rfps
  async getPublicRequests(page) {
    this.loading = true;
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.publicRfpsFilterParams);
    const params = {...this.publicRfpsFilterParams, direction, page};
    try {
      const {data} = await axiosInstance.get("rfp-public/search", {params});
      return data;
    } catch (e) {
      throw e;
    } finally {
      this.loading = false;
    }
  }

  // getting one specific request
  getPublicRequest(id, notLoading) {
    this.loading = !notLoading;
    return axiosInstance
      .get(`rfp-public/${id}`)
      .then(({data}) => {
        this.setOfferId(null);
        this.request = {...emptyPublicRequest, Request: data};
        return data;
      })
      .finally(() => (this.loading = false));
  }

  // setting interest status to public rfp (creating proposal)
  setInterestPublicRfp(requestId, values) {
    return axiosInstance
      .post(`rfp-public/${requestId}/proposal`, values)
      .then(({data}) => {
        this.setOfferId(data.Offer?.id);
        this.request = data;
      });
  }

  // setting not interest status to public rfp (deleting proposal)
  setNotInterestPublicRfp(requestId) {
    return axiosInstance
      .delete(`rfp-public/${requestId}/proposal`)
      .then(({data}) => this.getPublicRequest(requestId, true));
  }

  // setting filters for the contracts
  setPublicRfpsFilterParams(values) {
    this.publicRfpsFilterParams = values;
  }
}

const seller = new SellerStore();
export default seller;
