import * as yup from "yup";

export const schema = yup.object().shape({
  contacts: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      email: yup.string().nullable().email("Must be a valid email"),
      phone: yup.string().nullable(),
      position: yup.string(),
      workKinds: yup.string(),
    })
  ),
});

export const initialValues = {
  name: null,
  email: null,
  phone: null,
  position: "",
  workKinds: "",
};
