import React from "react";
import {useTranslation} from "react-i18next";
//local
import {InfoTooltip} from "components/InfoTooltip";

const PublicRfpsTableHead = () => {
  const {t} = useTranslation();

  return (
    <div className="requests-card_head">
      <div className="requests-card_head__column __wrapper __category">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_01")}
      </div>
      <div className="requests-card_head__column __wrapper __region">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_02")}
      </div>
      <div className="requests-card_head__column __wrapper __published-at">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_08")}
      </div>
      <div className="requests-card_head__column __wrapper __time-left df-row-center">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_03")}
        <InfoTooltip
          tooltip="PUBLIC_RFPS_PAGE_TABLE_COLUMN_03_TOOLTIP"
          centerText
          addIconWrapperClass="ml-2"
        />
      </div>
      <div className="requests-card_head__column __wrapper __customers">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_04")}
      </div>
      <div className="requests-card_head__column __wrapper __project-name">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_05")}
      </div>
      <div className="requests-card_head__column __wrapper __rfp-name">
        {t("PUBLIC_RFPS_PAGE_TABLE_COLUMN_06")}
      </div>
    </div>
  );
};

export default PublicRfpsTableHead;
