import React from "react";
import {useTranslation} from "react-i18next";
// local
import {ProjectLabel} from "components/ProjectLabel";
import ContactCard from "components/ContactCard";
import {IconMapMarker} from "components/icons";
import RequestDocuments from "components/requestCard/RequestDocuments";
import {color} from "constants/color.consts";
import cn from "classnames";

const RfpView = ({rfp, addClass}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn("rfp-info", {
        [addClass]: !!addClass,
      })}
    >
      <div>{t("JOINING_ORGANIZATION_RFP_INVITED")}</div>
      <div className="rfp-info__name">{rfp.name}</div>
      <div className="rfp-info__subtitle">{`${t("PROJECT")}:`}</div>
      <div className="df-row-center">
        {rfp.Project && (
          <ProjectLabel
            name={rfp.Project.name}
            code={rfp.Project.reference}
            wideName
            large
            width="400"
            addClass="mr-5"
          />
        )}
        {rfp.Project?.location && (
          <React.Fragment>
            <IconMapMarker addClass="mr-1" color={color.blue} />
            <span className="link">{rfp.Project.location}</span>
          </React.Fragment>
        )}
      </div>
      <div className="rfp-info__subtitle mt-8">{`${t("BUYER")}:`}</div>
      <div className="blue mb-4">{rfp.Buyer.name}</div>
      <ContactCard
        contact={{...rfp.ContactPerson, User: rfp.ContactPerson}}
        maxWidthFitContent
        blackContent
        addClass="organization-info__contact-card rfp-info mb-13"
      />
      <RequestDocuments
        revisions={rfp.documents}
        editable={false}
        columnTitles={{
          contracts: "CONTRACT",
          documents: "DOCUMENTS",
          plans: "PLANS",
          links: "LINKS",
        }}
        standardAgreement={rfp.standardAgreement}
        needZipButton
        showTabs
        twoRowView
        publicView
      />
    </div>
  );
};

export default RfpView;
