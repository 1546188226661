import React from "react";
import {useTranslation} from "react-i18next";
// local
import {InfoTooltip} from "components/InfoTooltip";
import {InitialsBadge} from "components/InitialsBadge";
import {dateFormat, timeFormat} from "helpers/datetime";
import {getFullName} from "helpers/helper";
import cn from "classnames";

export const OrganizationInvite = ({name, member, date, type, ownMember, addClass}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn("organization-card_body__name  __invite-info", {
        [addClass]: !!addClass,
      })}
    >
      <InitialsBadge
        firstName={member?.firstName}
        lastName={member?.lastName}
        isCurrentUser={ownMember}
        addClass="mr-1"
      />
      <span>{`${member?.firstName} ${member?.lastName}`}</span>
      <span className="mid-gray ml-1 mr-1">|</span>
      <span className="mr-1">{dateFormat(date)}</span>
      <InfoTooltip
        noTranslate
        centerText
        addWrapperClass="qualification-invite-info-tooltip"
        tooltip={`${t("QUALIFICATION_INVITE_TOOLTIP_01").format(
          name,
          getFullName(member?.firstName, member?.lastName),
          member?.email
        )}\n${t("QUALIFICATION_INVITE_TOOLTIP_02").format(
          dateFormat(date),
          timeFormat(date)
        )}\n\n${t(`QUALIFICATION_INVITE_TOOLTIP_${type}`)}`}
      />
    </div>
  );
};
