import React, {useState} from "react";
import {inject, observer} from "mobx-react";
// local
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconDownload, IconDocumentDalux} from "components/icons/index";
import {openNewTab} from "helpers/helper";
import {InfoTooltip} from "components/InfoTooltip";

const DownloadZip = inject("store")(
  observer(({store: {clientStore}, docs, linksView, revisionId, disabled}) => {
    const [link, setLink] = useState();
    const [loading, setLoading] = useState(false);

    const getZipLink = async () => {
      setLoading(true);
      const files = docs
        .filter((item) => (linksView ? item.url.includes("daluxFile") : true))
        .map((item) => {
          return {
            fileName: linksView ? item.url.split("/")[1] : item.name || item.File?.name,
            key: linksView
              ? item.url
              : item.s3responseRaw?.Key ||
                item.s3responseRaw?.key ||
                item.File?.s3responseRaw?.key ||
                item.File?.s3responseRaw?.Key,
          };
        });

      clientStore
        .downloadZIP(files, revisionId)
        .then((data) => {
          setLoading(false);
          setLink(data.Location);
          openNewTab(data.Location);
        })
        .finally(() => setLoading(false));
    };

    return (
      <React.Fragment>
        <ButtonTransparent
          icon={linksView ? <IconDocumentDalux /> : <IconDownload />}
          name={".zip"}
          small
          disabled={disabled}
          onClick={() => (link ? openNewTab(link) : getZipLink())}
          loading={!link && loading}
          tooltip={linksView && "RFP_DOCUMENTS_DOWNLOAD_ZIP_DALUX"}
          addClass="mr-1 pl-1 pr-1"
          style={{width: loading && "70px"}}
        />

        {disabled && <InfoTooltip tooltip="TOOLTIP_WAITING_FOR_ZIP_BUTTON" centerText />}
      </React.Fragment>
    );
  })
);

export default DownloadZip;
