// ======================================
// Working with the data of the user's organization. Data
// ======================================

import {makeAutoObservable} from "mobx";
//local
import axiosInstance from "../axios/index";
import AuthStore from "./auth.store";
import {showSuccess} from "helpers/notifications.helper";
import {formatCompanyNumber, directionReplacement} from "helpers/helper";
import {availableInArray} from "constants/select.consts";
import {initialAgreements} from "constants/user.consts";
import {defaultNameSearch} from "constants/user.consts";
import {allOffices, noOfficeItem, allOfficesItem} from "constants/buyer.consts";
import {findSuppliersFilterTabs} from "constants/tabs.const";
import {getFullName} from "helpers/helper";
import {hasPermission} from "helpers/helper";

class OrganizationsStore {
  // STATE. Observable and computed part
  // ****** COMMON ******
  btnLoading = false; // loading status for buttons
  loading = true; // loading status for parts of the page
  organizationDetail = {}; // data of current organization
  favoritesOrganization = []; // list of favourites organizations
  blacklistOrganization = []; // list of organizations in blacklist

  departments = null;
  // id of the label of the currently selected department
  get currentDepartmentId() {
    return AuthStore?.user?.ActiveOrganization?.lastSelectedDepartmentId ?? allOffices;
  }
  get projectsDropdownDepartments() {
    return this.departments
      ? [
          allOfficesItem,
          ...this.departments.map((item) => ({label: item.name, value: item.id})),
          noOfficeItem,
        ]
      : [allOfficesItem, noOfficeItem];
  }
  get projectDropdownDepartments() {
    return this.departments
      ? [
          ...this.departments?.map((item) => ({label: item.name, value: item.id})),
          noOfficeItem,
        ]
      : [noOfficeItem];
  }

  // ****** SEARCH AND FILTER ORGANIZATIONS ******
  suppliers = {}; // organization search settings and pagination
  // tabs for filter panel on find organization page
  searchPreferencesGroupSwitches = {
    entrepreneur: {
      keys: ["entrepreneurWithin", "entrepreneurOffers"],
      isActive: true,
    },
    realEstate: {
      keys: ["realEstateCompanyType", "realEstateServices", "realEstatePropertyType"],
      isActive: false,
    },
    materialSupplier: {
      keys: ["materialSupplierType"],
      isActive: false,
    },
    consulting: {
      keys: ["consultingServices"],
      isActive: false,
    },
    salesRental: {
      keys: ["salesRentalType"],
      isActive: false,
    },
    organizations: {
      keys: ["organizationsType"],
      isActive: false,
    },
  };
  // filter parameters for find organizations list
  filterParams = defaultNameSearch;
  // number of the active tab for filter panel on find organization page
  get activeFilterTab() {
    const tabNumber = findSuppliersFilterTabs.findIndex(
      (item) => item.filterKey === this.filterParams?.tab
    );
    return tabNumber > 0 ? tabNumber : 0;
  }

  // ****** MEMBERS ******
  // members of the current organization, divided by roles
  organizationMembers = {
    owner: [],
    admin: [],
    member: [],
  };
  get organizationMembersSelectList() {
    const members = [
      ...this.organizationMembers.owner,
      ...this.organizationMembers.admin,
      ...this.organizationMembers.member,
    ];
    return members.length
      ? members
          .map((val) => ({
            value: val.UserId,
            label: getFullName(val.User?.firstName, val.User?.lastName),
          }))
          .filter((val) => val.label)
      : [];
  }
  memberInfo = []; // members who were invited

  // ****** SUBSCRIPTIONS, RATINGS, MANDATORY PARAMETRES ******
  // list of all available subscriptions in current organization
  subscriptions = [];
  subscriptionRoles = [];
  overallRating = {}; // data for overall rating on our profile page
  ratings = []; // list of all available ratings
  // Mandatory parametres
  isBankId = false; // BankId availability status
  isOrgSettings = false; // Save search preferences availability status
  isOnboardingVideo = false; // Onboarding about video availability status
  isAdvertisementVideo = false; // Advertisement intro video for free users

  // ****** INVITES ******
  invites = []; // list of invites
  invitationStep = 0; // number of invitation step in invitation modal window
  invitationUserInfo = {}; // data of invitation user info
  invitationRequestId = ""; // id of request from which the invitation was created
  inviteUsers = []; // list of invite users for InviteUsers tab on right sidebar
  invitesStatus = ""; // status for switching between invite process pages

  constructor() {
    makeAutoObservable(this);
  }

  // ACTIONS

  // ****** WORKING WITH ACTIVE (CURRENT) ORGANIZATION ******

  // creating new organization
  async createNewOrg(parameters, organizationName) {
    const body = {
      organizationName,
    };
    if (parameters && Object.keys(parameters).length) {
      Object.keys(parameters).forEach((key) => {
        body[key] = parameters[key];
      });
    }

    try {
      await axiosInstance.post(`onboarding/create-new-organization`, body);
    } catch (e) {
      throw e;
    }
  }

  // editing main information of active organization (name, description, url)
  nameDescriptionUrlEdit(values) {
    return axiosInstance
      .post("our-profile/name-description-url/edit", values)
      .then(({data}) => {
        if (data.message) {
          showSuccess(data.message);
        }
        return data;
      });
  }

  // setting rating to organization
  setOrganizationRating(values) {
    return axiosInstance
      .post("organization/set-organization-rating", values)
      .then(({data}) => {
        return data;
      });
  }

  getID06OrganizationData(number) {
    return axiosInstance
      .get(`company/activity-status?companyNumber=${number}`)
      .then(({data}) => data);
  }

  getOrganizationSnapshots(companyNumber) {
    return axiosInstance
      .get(`company/number/${companyNumber}/snapshots`)
      .then(({data}) => data);
  }

  createOrganizationSnapshot(companyNumber) {
    return axiosInstance
      .post(`company/number/${companyNumber}/snapshots/generate`)
      .then(({data}) => data);
  }

  // setting information of active (current) organization to store
  setOrganizationDetailActive(userActiveOrganizationId) {
    const isActive = this.organizationDetail.id === userActiveOrganizationId;
    this.organizationDetail = {...this.organizationDetail, isActive};
  }

  // updating contact information of active (current) organization
  updateOrganizationContacts(values) {
    return axiosInstance.post("organization/contacts", values).then(({data}) => data);
  }

  // uploading picture for organization logo
  loadOrganizationLogo(values, extra) {
    const data = new FormData();
    data.append("file", values);
    return axiosInstance
      .post(`our-profile/logotype/upload`, data, extra)
      .then(({data}) => {
        return data.aws;
      });
  }

  // deleting organization logo
  deleteOrganizationLogo() {
    return axiosInstance.delete("our-profile/logotype/delete").then(({data}) => {
      showSuccess(data.message);
      return data.aws;
    });
  }

  // setting report to active (current) organization
  reportOrganization(values) {
    return axiosInstance.post("organization/report", values);
  }

  // getting list of all available subscriptions in current organization
  async getAvailableSubscriptions() {
    const {data} = await axiosInstance.get("subscribes/products");
    this.subscriptions = data;
  }

  // getting list of all available subscription roles
  async getAvailableSubscriptionRoles(permission) {
    const params = permission ? {permission} : {};
    const {data} = await axiosInstance.get("subscribes/roles", {params});
    this.subscriptionRoles = data;
  }

  // setting specific subcription as active for the current organization
  setActiveSubscription(values) {
    return axiosInstance.post("organization/start-subscribe", values).then(({data}) => {
      return data;
    });
  }

  // setting email for payments for the current organization
  setPaymentEmail(email) {
    return axiosInstance.post("organization/set-payment-email", email).then(({data}) => {
      return data;
    });
  }

  // setting filters for the Find organization page
  setFilterParams(values) {
    this.filterParams = values;
  }

  // ****** WORKING WITH OTHER ORGANIZATIONS ******

  // getting list of companies on Find companies page
  searchSuppliers(page, notLoading, customUrl) {
    this.loading = !notLoading;
    this.suppliers = notLoading ? {...this.suppliers} : {};
    // substituting the required sort value instead of the default value
    const direction = directionReplacement(this.filterParams);
    const params = page
      ? {...this.filterParams, direction, page}
      : {...this.filterParams, direction};
    return axiosInstance
      .get(customUrl ?? `organization/search`, {params})
      .then(({data, ...response}) => {
        const sp = data.organizations.map((org) => {
          const Organization = {...org, SearchPreference: undefined};
          return {...org.SearchPreference, Organization};
        });
        return {...response, data: {...data, searchPreferences: sp}};
      })
      .then(({data}) => (this.suppliers = data))
      .finally(() => (this.loading = false));
  }

  // updating list of companies on Find companies page
  updateSupplierOrganization(id, payload) {
    this.suppliers = {
      ...this.suppliers,
      searchPreferences: this.suppliers.searchPreferences.map((supplier) =>
        supplier.OrganizationId === id
          ? {...supplier, Organization: {...supplier.Organization, ...payload}}
          : supplier
      ),
    };
  }

  // updating list of companies on Find companies page
  updateSupplierOrganizationCompany(organizationId, payload) {
    this.suppliers = {
      ...this.suppliers,
      searchPreferences: this.suppliers.searchPreferences.map((supplier) =>
        supplier.OrganizationId === organizationId
          ? {...supplier, Organization: {...supplier.Organization, Company: payload}}
          : supplier
      ),
    };
  }

  // getting a list of organizations based on different filtering settings
  saveSearch(values) {
    Object.entries(this.searchPreferencesGroupSwitches).forEach(([_, value]) => {
      if (!value.isActive && value.keys.length) {
        value.keys.forEach((key) => {
          values[key] = [];
        });
      }
    });
    // need to processing region Sweden in availableIn array
    const data = {
      ...values,
      availableIn: values.availableIn?.includes("FIRST_SETUP_REGION_00")
        ? availableInArray.map(({value}) => value)
        : values.availableIn,
    };
    return axiosInstance
      .post("organization/save-search-preferences", data)
      .then(({data}) => {
        this.setOrganizationDetail({
          SearchPreference: data.SearchPreference ? data.SearchPreference : {},
        });
        this.checkSearchPreferencesGroupSwitches();
      });
  }

  // getting list of favorites organizations
  getFavorites() {
    const params = {favorite: true, size: 10000};
    return axiosInstance
      .get("organization/search", {params})
      .then(({data: {organizations}}) => {
        this.favoritesOrganization = organizations;
        this.loading = false;
        return organizations;
      })
      .finally(() => (this.loading = false));
  }

  // getting list of blacklisted organizations
  getBlackList() {
    const params = {blackList: true, size: 10000};
    return axiosInstance
      .get("organization/search", {params})
      .then(({data: {organizations}}) => {
        this.blacklistOrganization = organizations;
      });
  }

  // adding organization in blacklist
  addToBlackList(organizationId, values) {
    const sendData = {organizationId, ...values};
    return axiosInstance
      .post(`organization/add-remove-blacklisted-organization`, sendData)
      .then(() => {
        this.getBlackList();
        showSuccess();
      });
  }

  // adding organization in favorites list
  addToFavorite(organizationId) {
    return axiosInstance
      .post("organization/add-supplier-organization", {organizationId})
      .then(() => {
        showSuccess();
        this.getFavorites();
        this.getOrganizationDetail(organizationId, true);
      });
  }

  // romoving organization in favorites list
  removeFromFavorite(organizationId) {
    return axiosInstance
      .post("organization/remove-supplier-organization", {organizationId})
      .then(() => {
        showSuccess();
        this.getFavorites();
      });
  }

  // getting list of all organization ratings to specific organization
  getListAllRatings(organizationId) {
    return axiosInstance
      .get(`organization/list-all-ratings-for/${organizationId}`)
      .then(({data}) => {
        this.overallRating = data.overallScore;
        this.ratings = data.ratings;
      });
  }

  // getting information of specific organization
  getOrganizationDetail(organizationId, notLoading, isOnlyDataUpdate) {
    this.loading = !notLoading;
    return axiosInstance.get(`organization/${organizationId}`).then(({data}) => {
      !isOnlyDataUpdate && this.setOrganizationDetail(data.organization);
      !isOnlyDataUpdate && this.checkSearchPreferencesGroupSwitches();
      !isOnlyDataUpdate &&
        hasPermission(["READ_OTHER_CONTACT_PERSONS"]) &&
        this.getAnotherOrganizationContactsRegions(organizationId);
      this.loading = false;
      return data;
    });
  }

  // configuring the filter panel based on the current organization's settings
  checkSearchPreferencesGroupSwitches() {
    Object.entries(this.searchPreferencesGroupSwitches).forEach(([_, value]) => {
      if (value.keys?.length) {
        value.keys.forEach((key) => {
          value.isActive = !!(
            this.organizationDetail.SearchPreference[key] &&
            this.organizationDetail.SearchPreference[key].length
          );
        });
      }
    });
  }

  // setting filter panel configuration in store for for correct display this panel
  setSearchPreferencesGroupSwitches(key) {
    if (Object.keys(this.searchPreferencesGroupSwitches).includes(key)) {
      this.searchPreferencesGroupSwitches[key].isActive =
        !this.searchPreferencesGroupSwitches[key].isActive;
    }
  }

  // setting information of specific organization to store
  setOrganizationDetail(values) {
    this.organizationDetail = Object.assign(this.organizationDetail, values);
  }

  // update ORGANIZATION member information after editing
  membersEdit(values) {
    return axiosInstance.post("our-profile/members/edit", values).then(({data}) => {
      showSuccess(data.message);
      this.setOrganizationDetail(data.organization);
      return data;
    });
  }

  // ****** WORKING WITH MEMBERS ******

  // getting list of organization members
  async getOrganizationMembers(permission) {
    const params = permission ? {permission} : {};
    const {
      data: {organizationMembers},
    } = await axiosInstance.get(`organization/members`, {params});
    this.organizationMembers = {
      owner: [],
      admin: [],
      member: [],
    };
    organizationMembers.forEach((item) => {
      this.organizationMembers[item.role] = [
        ...this.organizationMembers[item.role],
        item,
      ];
    });
  }

  // getting list of organization members
  async getAnotherOrganizationMembers(id) {
    const {data} = await axiosInstance.get(`organization/${id}/members`);
    return data;
  }

  // getting contacts regions of another organization
  async getAnotherOrganizationContactsRegions(id) {
    const {data} = await axiosInstance.get(
      `organization/${id}/contacts/rfts-project-regions`
    );
    this.setOrganizationDetail({
      Contacts: this.organizationDetail?.Contacts?.map((contact) => ({
        ...contact,
        ...(data.find((item) => item.UserId === contact.UserId) ?? {}),
      })),
    });
  }

  // getting contact of another organization
  async getAnotherOrganizationContact(id, contactId) {
    const {data} = await axiosInstance.get(`organization/${id}/contacts/${contactId}`);
    return data;
  }

  // updating organization member
  updateMember(userId, params) {
    return axiosInstance
      .post(`organization/members/${userId}`, {
        ...params,
        availableIn: params.availableIn?.includes("FIRST_SETUP_REGION_00")
          ? availableInArray.map(({value}) => value)
          : params.availableIn,
      })
      .then(() => this.getOrganizationMembers());
  }

  // removing organization member
  removeMember(userId) {
    return axiosInstance.delete(`organization/members/${userId}`);
  }

  // ****** WORKING WITH JOINING TO ORGANIZATION ******

  // getting list of joining orzanization requests
  async requestsToJoinOrganization() {
    const {
      data: {joinRequests},
    } = await axiosInstance.get("organization/active-join-requests");

    this.memberInfo = joinRequests;
  }

  // accepting a current user request to join to inviting organization
  joinRequestAccept(requestedUserId) {
    return axiosInstance
      .post("organization/join-request/accept", {requestedUserId})
      .then(({data}) => {
        if (data) {
          showSuccess(data.message || "Join request accepted");
        }
        return data;
      });
  }

  // rejecting a current user request to join to inviting organization
  joinRequestDeny(requestedUserId) {
    return axiosInstance
      .post("organization/join-request/deny", {requestedUserId})
      .then(({data}) => {
        if (data) {
          showSuccess(data.message || "Join request denied");
        }
        return data;
      });
  }

  // ****** WORKING WITH INSURANCES AND AGREEMENTS ******

  // uploading file for insurance
  insuranceUploadFile(values, extra) {
    const data = new FormData();
    data.append("file", values);
    return axiosInstance
      .post(`our-profile/insurance/upload`, data, extra)
      .then(({data}) => {
        return data;
      });
  }

  // updating insurance data after editing
  insuranceEdit(values) {
    return axiosInstance.post("our-profile/insurance/edit", values).then(({data}) => {
      return data;
    });
  }

  // uploading file for union agreement
  unionAgreementUploadFile(values, extra) {
    const data = new FormData();
    data.append("file", values);

    return axiosInstance
      .post(`our-profile/union-agreement/upload`, data, extra)
      .then(({data}) => data);
  }

  // updating union agreement data after editing
  unionAgreementEdit(values) {
    return axiosInstance
      .post("our-profile/union-agreement/edit", values)
      .then(({data}) => data);
  }

  // ****** WORKING WITH LICENCES, POLICIES, RATINGS ******

  // uploading file for licence on active (current) organizations
  licensesUploadFile(values, extra) {
    const data = new FormData();
    data.append("file", values);
    return axiosInstance
      .post(`our-profile/licenses/upload`, data, extra)
      .then(({data}) => data);
  }

  // updating licence data after editing
  licensesEdit(values) {
    return axiosInstance.post("our-profile/licenses/edit", values).then(({data}) => data);
  }

  // uploading file for policy on active (current) organizations
  policyUploadFile(values, extra) {
    const data = new FormData();
    data.append("file", values);
    return axiosInstance
      .post(`our-profile/policy/upload`, data, extra)
      .then(({data}) => data);
  }

  // updating policy data after editing
  policyEdit(values) {
    return axiosInstance.post("our-profile/policy/edit", values).then(({data}) => data);
  }

  // updating organization credit rating
  getNewCreditRating(id) {
    return axiosInstance.post(`organization/${id}/updateCreditDataPremium`);
  }

  // ****** WORKING WITH INVITES ******

  // getting list of invites in active organization
  getInvites() {
    this.loading = true;
    axiosInstance
      .get("invites/fetch-invites")
      .then(({data}) => {
        this.invites = data.organizationInvites;
      })
      .finally(() => (this.loading = false));
  }

  // sending invite from active organization to specific email
  sendInvite(values) {
    return axiosInstance.post("invites/send-invites", values).then(() => {
      this.getInvites();
    });
  }

  // getting list of users invited from active (current) organization
  getInviteUsers() {
    return axiosInstance.get("/join-requests").then(({data}) => {
      this.inviteUsers = data;
    });
  }

  // sending invite to specific user from active (current) organization
  sendInviteUsers(values) {
    return axiosInstance.post("/join-requests", values).then(({data}) => {
      this.addToInviteUsers(data);
    });
  }

  // removing invite of specific user from active (current) organization
  deleteInviteUsers(invitationId) {
    return axiosInstance.post(`join-requests/${invitationId}/cancel`).then(() => {
      this.removeFromInviteUsers(invitationId);
    });
  }

  // adding new invited users in store list after sending invite to specific user
  addToInviteUsers(values) {
    this.inviteUsers = [...this.inviteUsers, ...values];
  }

  // removing new invited users in store list after sending invite to specific user
  removeFromInviteUsers(value) {
    this.inviteUsers = this.inviteUsers.filter((item) => item.id !== value);
  }

  // setting a specific invitation step
  setInvitationStep(value) {
    this.invitationStep = value;
  }

  // setting invitation code corresponding to the specific invitation step
  setInviteStatus(value) {
    this.invitesStatus = value;
  }

  // sending verfication code in the form of sms to phone to completing invitation
  sendInviteVerificationCode(values, token) {
    this.invitationUserInfo = values;
    return axiosInstance
      .post("invites/send-verify-sms", values, {
        params: {token},
      })
      .then(({data}) => {
        this.invitationRequestId = data?.response?.request_id ?? null;
        return data;
      });
  }

  // checking sms verfication code to completing invitation
  invitationCheckSmsCode(code) {
    const request_id = this.invitationRequestId;
    const data = {
      email: this.invitationUserInfo.email,
      mobilePhone: this.invitationUserInfo.mobilePhone,
      token: this.invitationUserInfo.token,
      code,
      request_id,
    };
    return axiosInstance
      .post("invites/check-sms-code", data)
      .then(({data}) => showSuccess(data.message));
  }

  // setting invitation info during onboarding organization
  invitationOnboarding(values) {
    const userInfo = this.invitationUserInfo;
    const data = {
      ...values,
      ...userInfo,
      companyNumber: formatCompanyNumber(values.companyNumber, true),
    };
    return axiosInstance.post("invites/onboard", data).then(({data}) => data);
  }

  // ****** WORKING WITH ORGANIZATION EVALUATION ******

  // getting evaluation of specific organization
  getOrganizationEvaluation(evaluatedOrganizationId) {
    return axiosInstance.get(`organization/evaluation/${evaluatedOrganizationId}`);
  }

  // creating specific evaluation to active (current) organization
  createOrganizationEvaluation(evaluatedOrganizationId, message, ProjectId) {
    return axiosInstance.post(`organization/evaluation/${evaluatedOrganizationId}`, {
      message,
      ProjectId,
    });
  }

  // updating evaluation of specific organization
  updateOrganizationEvaluation(evaluatedOrganizationId, evaluatedId, message, ProjectId) {
    return axiosInstance.put(
      `organization/evaluation/${evaluatedOrganizationId}/${evaluatedId}`,
      {message, ProjectId}
    );
  }

  // deleting specific evaluation to active (current) organization
  removeOrganizationEvaluation(evaluatedOrganizationId, evaluatedId) {
    return axiosInstance.delete(
      `organization/evaluation/${evaluatedOrganizationId}/${evaluatedId}`
    );
  }

  // ****** WORKING WITH ORGANIZATION AGREEMENTS ******

  // getting agreements of specific organization
  getOrganizationAgreements(organizationId) {
    return axiosInstance.get(`organization/${organizationId}/agreements`).then(({data}) =>
      initialAgreements.map((item) => ({
        ...item,
        ...data.find((value) => value.type === item.type),
      }))
    );
  }

  // creating and updating specific agreement to specific organization
  updateOrganizationAgreement(organizationId, values) {
    return axiosInstance.put(
      `organization/${organizationId}/agreements/${values.type}`,
      values
    );
  }

  // attaching document for specific agreement to specific organization
  loadOrganizationAgreementFile(organizationId, type, document) {
    const data = new FormData();
    data.append("file", document);
    return axiosInstance
      .post(`organization/${organizationId}/agreements/${type}/document`, data)
      .then(({data}) => data);
  }

  // deleting document for specific agreement to specific organization
  deleteOrganizationAgreementFile(organizationId, type, documentId) {
    return axiosInstance.delete(
      `organization/${organizationId}/agreements/${type}/document/${documentId}`
    );
  }

  // ****** WORKING WITH ORGANIZATION AGREEMENT ITEMS ******

  // creating specific agreement to specific organization
  createOrganizationAgreementItem(organizationId, type, values) {
    return axiosInstance.post(
      `organization/${organizationId}/agreements/${type}/items`,
      values
    );
  }

  // updating specific agreement to specific organization
  updateOrganizationAgreementItem(organizationId, type, agreementItemId, values) {
    return axiosInstance.put(
      `organization/${organizationId}/agreements/${type}/items/${agreementItemId}`,
      values
    );
  }

  // deleting specific agreement to specific organization
  deleteOrganizationAgreementItem(organizationId, type, agreementItemId) {
    return axiosInstance.delete(
      `organization/${organizationId}/agreements/${type}/items/${agreementItemId}`
    );
  }

  // attaching document for specific agreement to specific organization
  loadOrganizationAgreementItemFile(organizationId, type, agreementItemId, document) {
    const data = new FormData();
    data.append("file", document);
    return axiosInstance
      .post(
        `organization/${organizationId}/agreements/${type}/items/${agreementItemId}/document`,
        data
      )
      .then(({data}) => data);
  }

  // deleting document for specific agreement to specific organization
  deleteOrganizationAgreementItemFile(organizationId, type, agreementItemId, fileId) {
    return axiosInstance.delete(
      `organization/${organizationId}/agreements/${type}/items/${agreementItemId}/document/${fileId}`
    );
  }

  // ****** WORKING WITH ONBOARDING STATUSES AND JOINING ******

  // getting data on onboarding info for active (current) organization
  async getOrganizationOnboardingStatus(OrganizationId) {
    const {
      data: {organizationOnboarding},
    } = await axiosInstance.post("/organization/fecth-organization-onboarding-status", {
      OrganizationId,
    });
    return organizationOnboarding;
  }

  // setting current onboarding steps for active (current) organization
  async setOrganizationOnboardingStatus(OrganizationId, values) {
    const {data} = await axiosInstance.post(
      "/organization/set-organization-onboarding-status",
      {
        OrganizationId,
        steps: values,
      }
    );
    return data;
  }

  // setting the statuses of mandatory one-time redirects
  setMandatoryStatuses(data) {
    this.isBankId = !!data.bankId;
    this.isOrgSettings = !!data.orgSettings;
    this.isOnboardingVideo = !!data.onboardingVideo;
    this.isAdvertisementVideo = !!data.advertisementVideo;
  }

  // setting status depending on the success of save settings on the SaveSearchPreferences page
  setOnboardingVideoStatus(status) {
    this.isOnboardingVideo = status;
  }

  // setting status depending on the viewing of the promotional video for free users
  setAdvertisementVideoStatus(status) {
    this.isAdvertisementVideo = status;
  }

  // accepting a specific request to join an organization
  acceptJoinRequest(joinRequestId) {
    return axiosInstance.post(`join-requests/${joinRequestId}/accept`);
  }

  // rejecting a specific request to join an organization
  rejectJoinRequest(joinRequestId) {
    return axiosInstance.post(`join-requests/${joinRequestId}/decline`);
  }

  // ****** WORKING WITH ONBOARDING STATUSES AND JOINING ******

  // getting settings for active (current) organization
  async getOrganizationSettings() {
    const {data} = await axiosInstance.get("/organization/settings");
    return data;
  }

  // updating settings for active (current) organization
  async updateOrganizationSettings(settings) {
    const {data} = await axiosInstance.put("/organization/settings", settings);
    AuthStore.user.ActiveOrganization = {
      ...AuthStore.user.ActiveOrganization,
      ...data,
    };
    return data;
  }

  // ****** WORKING WITH ORGANIZATION DEPARTMENT LABELS ******

  // getting departments labels list of own organization
  getOrganizationDepartmentLabels() {
    return axiosInstance
      .get("organization/label/department")
      .then(({data}) => this.setOrganizationDepartmentLabels(data));
  }

  // getting specific departments label of own organization
  getOrganizationDepartmentLabel(departmentLabelId) {
    return axiosInstance
      .get(`organization/label/department/${departmentLabelId}`)
      .then(({data}) => data);
  }

  // creating department label to own organization
  createOrganizationDepartmentLabel(values) {
    return axiosInstance.post("organization/label/department", values);
  }

  // updating specific department label to own organization
  updateOrganizationDepartmentLabel(departmentLabelId, values) {
    return axiosInstance.put(
      `organization/label/department/${departmentLabelId}`,
      values
    );
  }

  // deleting specific department label to own organization
  deleteOrganizationDepartmentLabel(departmentLabelId) {
    return axiosInstance.delete(`organization/label/department/${departmentLabelId}`);
  }

  setOrganizationDepartmentLabels(data) {
    this.departments = data;
  }

  // ****** WORKING WITH ORGANIZATION SMART TEMPLATES ******

  // getting smart templates list of own organization
  getOrganizationSmartTemplates() {
    return axiosInstance
      .get("organization/rft-smart-form-template")
      .then(({data}) => data);
  }

  // creating smart templates list of own organization
  createOrganizationSmartTemplate(values) {
    return axiosInstance.post("organization/rft-smart-form-template", values);
  }

  // getting specific smart template of own organization
  getOrganizationSmartTemplate(smartTemplateId) {
    return axiosInstance
      .get(`organization/rft-smart-form-template/${smartTemplateId}`)
      .then(({data}) => data);
  }

  // updating specific smart template of own organization
  saveOrganizationSmartTemplate(smartTemplateId, values) {
    return axiosInstance.put(
      `organization/rft-smart-form-template/${smartTemplateId}`,
      values
    );
  }

  // deleting specific smart template of own organization
  removeOrganizationSmartTemplate(smartTemplateId) {
    return axiosInstance.delete(
      `organization/rft-smart-form-template/${smartTemplateId}`
    );
  }

  // ****** WORKING WITH ORGANIZATION HISTORY ******

  changeBlacklistHistoryComment(historyId, values, historyEvents) {
    const BlacklistHistoryEvents = historyEvents.map((item) =>
      item.id === historyId ? {...item, comment: values.comment} : item
    );
    return this.updateBlacklistHistoryItem(historyId, values).then(
      () => BlacklistHistoryEvents
    );
    //   organizationStore.updateSupplierOrganization(organization.id, {
    //     BlacklistHistoryEvents,
    //   })
    // );
  }

  // updating specific blacklist history item
  updateBlacklistHistoryItem(historyId, values) {
    return axiosInstance.put(`history-log/blacklist/${historyId}`, values);
  }
}

const organizations = new OrganizationsStore();
export default organizations;
