import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
//local
import {showSuccess} from "helpers/notifications.helper";
import {toProjectId} from "constants/routes.const";
import {ConfirmModal} from "components/ConfirmModal";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {PublicPFPPublishModal} from "components/modals/PublicPFPPublishModal";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {CustomTooltip} from "components/CustomTooltip";
import {defaultNameFieldSearch} from "constants/user.consts";
import {IconTrashCan, IconRocketUp, IconBullHorn} from "components/icons";
import {VideoInstructionLink} from "components/VideoInstructionLink";
import {rfpStatuses} from "constants/buyer.consts";
import {LockValue} from "components/LockValue";
import {hasPermission} from "helpers/helper";

const WaitSaveButton = inject("store")(
  observer(({store: {buyer, auth}, inText, addClass}) => {
    const {
      currentRequest,
      isFilledRequiredRFPFields,
      isPublishedRequest,
      newPublishedRFPRecipients,
      isDraftSaved,
    } = buyer;
    const {user} = auth;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPublicPFPPublishModal, setPublicPFPPublishModal] = useState(false);
    const isDraft = currentRequest.status === rfpStatuses.DRAFT;

    const checkRecipients = async () => {
      if (!Object.values(currentRequest.recipients).flat().length)
        ConfirmModal({
          title: t("WANT_SAVE_WITHOUT_RECIPIENTS"),
          text: t("CANT_GET_ANY_OFFERS"),
          okText: "CANCEL_SELECT_NOW",
          cancelText: "DO_THIS_LATER",
          noCancelButton: true,
          type: "warning",
          onOk: () => buyer.setActiveRequestTab(1),
          onCancel: clickPublish,
          noCancelBefore: true,
        });
      else clickPublish();
    };

    const clickPublish = () =>
      ConfirmModal({
        title: t("WANT_SET_STATUS"),
        text: t("CANT_DELETE_RECIPIENTS"),
        type: "warning",
        onOk: () => {
          setLoading(true);
          buyer
            .saveCurrentRequest(currentRequest.id, true)
            .then((data) =>
              buyer
                .changeRequestStatus(currentRequest?.id, rfpStatuses.PUBLISHED)
                .then(() => toggleToViewMode(data.message))
            );
        },
      });

    const clickRemove = () =>
      ConfirmModal({
        title: t("WANT_DELETE_RFP"),
        text: t("CANT_RETURN"),
        type: "warning",
        onOk: () =>
          buyer
            .deleteRequest(currentRequest.id)
            .then(() => navigate(toProjectId(currentRequest.ProjectId))),
      });

    const toggleToViewMode = (successMessage) => {
      showSuccess(isDraft ? t("SUCCESSFULLY_SAVED_PUBLISHED") : t(successMessage));
      buyer.setEditStatus(false);
      buyer.setNewRFPStatus(false);
      buyer.setActiveRequestTab(0);
      setLoading(false);
      buyer.setRfpRecipientsFilterParams(defaultNameFieldSearch);
    };

    const handleChangeRequest = (field, value, needSaveRequest) => {
      buyer.updateCurrentRequestInfo({[field]: value}, !!needSaveRequest);
    };

    const saveRequest = async () => {
      // fill newPublishedRFPRecipients object if RFP in PUBLISHED status
      const newRFPRecipients = {
        ...newPublishedRFPRecipients,
        numberOrganizations: isDraft
          ? currentRequest.recipients?.registered?.length
          : currentRequest.recipients?.registered?.filter(
              (item) =>
                !newPublishedRFPRecipients.notRemoveRegistered
                  .map((value) => value.id)
                  .includes(item.id)
            ).length,
        numberEmails: isDraft
          ? currentRequest.recipients?.notRegistered?.length
          : currentRequest.recipients?.notRegistered?.filter(
              (item) => !newPublishedRFPRecipients.notRemoveNotRegistered.includes(item)
            ).length,
      };
      buyer.setNewPublishedRFPRecipients(newRFPRecipients);

      !currentRequest.ContactPersonId &&
        buyer.updateCurrentRequestInfo({ContactPersonId: user.id});

      if (isDraft && !currentRequest.isPublic) checkRecipients();
      else {
        setLoading(true);
        const data = await buyer.saveCurrentRequest(currentRequest.id, true);
        if (isDraft) setPublicPFPPublishModal(true);
        else toggleToViewMode(data.message);
      }
    };

    return (
      <React.Fragment>
        <div
          className={`request_head__btn ${inText ? "in-text-position" : ""} ${
            addClass ?? ""
          }`}
        >
          {user.ActiveOrganization?.allowPublicRft && (
            <div className="df-row-center mb-3">
              <VideoInstructionLink
                label="VIDEO_LINK_RFP_EDIT_PUBLIC_RFP_LABEL"
                link="VIDEO_LINK_RFP_EDIT_PUBLIC_RFP_LINK"
                uppercase
                bold
                addClass="mr-4"
              />
              <div className="request_head__btn__status-row">
                <LockValue
                  canPermissions={["ENABLE_SWITCH_OFF_OPEN_RFT_TOGGLE"]}
                  needLabel
                  addClassIcon="open-rft-switch-locked-icon"
                  needShowModalToSubscribe
                >
                  <CustomRadioSwitch
                    name="isPublic"
                    checked={currentRequest.isPublic}
                    uppercase
                    small
                    addWrapperClass="mr-1"
                    isActive={(checked) =>
                      hasPermission(
                        ["ENABLE_SWITCH_OFF_OPEN_RFT_TOGGLE"],
                        user.permissions
                      ) && handleChangeRequest("isPublic", checked, isDraft)
                    }
                  />
                </LockValue>

                <CustomTooltip text="CREATE_RFP_PUBLIC_RFP_SWITCH_LABEL">
                  <div className="df hght-21">
                    <IconBullHorn />
                  </div>
                </CustomTooltip>
              </div>
            </div>
          )}
          <ButtonTransparent
            nonTransparent
            name={t(isPublishedRequest ? "SAVE_REQUEST" : "SAVE_AS_DRAFT")}
            type="button"
            color="green"
            onClick={saveRequest}
            icon={<IconRocketUp />}
            loading={loading}
            addClass="save-publish-button"
            disabled={!isFilledRequiredRFPFields}
          />
          {currentRequest.status === rfpStatuses.DRAFT && !inText && (
            <div className="df-row-jce-center mt-1">
              {isDraftSaved && (
                <span className="__uppercase fz-12 red">{t("RFP_EDIT_DRAFT_SAVED")}</span>
              )}
              <ButtonTransparent
                name="REMOVE"
                noBorder
                color="red"
                small
                icon={<IconTrashCan />}
                onClick={clickRemove}
              />
            </div>
          )}
          {!user.ActiveOrganization?.allowPublicRft && (
            <VideoInstructionLink
              label="VIDEO_LINK_RFP_EDIT_DISABLED_PUBLIC_RFPS_LABEL"
              link="VIDEO_LINK_RFP_EDIT_DISABLED_PUBLIC_RFPS_LINK"
              uppercase
              bold
              addClass="mt-4"
            />
          )}
        </div>
        {isPublicPFPPublishModal && (
          <PublicPFPPublishModal
            onSave={() =>
              buyer
                .changeRequestStatus(currentRequest.id, rfpStatuses.PUBLISHED)
                .then(() => toggleToViewMode())
            }
            onClose={() => {
              setPublicPFPPublishModal(false);
              setLoading(false);
            }}
          />
        )}
      </React.Fragment>
    );
  })
);

export default WaitSaveButton;
