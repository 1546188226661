import {useState, useEffect, useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
  getStorage,
  setStorage,
  getSessionStorage,
  setSessionStorage,
} from "helpers/storage";
import {
  isEmptyObject,
  filterObjectValidKeys,
  getUrlParams,
  parseUrlParams,
} from "helpers/helper";

// returns the actual dimensions of a specific DOM element
// if you need a separation of logic depending on the endpoint response, but first you need to generate something
// and you can adjust the dimensions after the endpoint response
export const useDimensions = (myRef, repeat, resultWidth) => {
  const getDimensions = () => ({
    width: myRef?.current?.offsetWidth,
    height: myRef?.current?.offsetHeight,
  });

  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  useEffect(() => {
    const handleDimensions = () => setDimensions(getDimensions());
    window.addEventListener("DOMNodeInserted", handleDimensions);
    if (repeat && !myRef?.current?.offsetWidth) {
      const checkWidth = setInterval(() => {
        handleDimensions();
      }, 200);
      return () => {
        clearInterval(checkWidth);
        window.removeEventListener("DOMNodeInserted", handleDimensions);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef, repeat, myRef?.current?.offsetWidth]);

  return dimensions;
};

const getData = {
  local: (key) => getStorage(key),
  session: (key) => getSessionStorage(key),
};
const setData = {
  local: (key, value) => setStorage(key, value),
  session: (key, value) => setSessionStorage(key, value),
};

export const useSavedFilters = ({
  key,
  defaultValue = {},
  initialValue,
  updateFilters,
  storage = "local",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setInnerFilters] = useState(
    initialValue ? filterObjectValidKeys(initialValue) : null
  );

  const updateUrlParams = useCallback(
    (values) => {
      navigate({search: getUrlParams(values), replace: true});
    },
    [navigate]
  );

  const setFilters = useCallback(
    (values) => {
      const params = parseUrlParams(values);
      setData[storage](key, JSON.stringify(params));
      updateUrlParams(params);
      setInnerFilters(params);
      updateFilters && updateFilters(params);
    },
    [key, updateFilters, updateUrlParams, storage]
  );

  const clearFilters = () => setFilters({...defaultValue});

  useEffect(() => {
    const urlParams = Object.fromEntries(new URLSearchParams(location.search));
    const storageParams = JSON.parse(getData[storage](key));
    const params = isEmptyObject(filters)
      ? isEmptyObject(urlParams)
        ? storageParams ?? defaultValue
        : urlParams
      : filters;
    setFilters(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [filters, setFilters, clearFilters];
};
