import React, {memo} from "react";

export const IconStandPhone = memo(({color, height, width, addClass}) => {
  return (
    <svg
      width={width || "16"}
      height={height || "15"}
      viewBox="0 0 16 15"
      fill="none"
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7971 6.67237C13.0164 6.67237 11.6011 6.39112 11.1601 5.95012C10.8894 5.67937 10.8256 5.34037 10.7739 5.06737C10.7101 4.73137 10.6719 4.63162 10.5249 4.56787C9.84838 4.27687 8.87788 4.11037 7.86163 4.11037C6.85663 4.11037 5.89663 4.27462 5.22763 4.56037C5.08213 4.62262 5.04463 4.72237 4.98238 5.05762C4.93138 5.33137 4.86838 5.67187 4.59838 5.94187C4.35238 6.18787 3.83713 6.35737 3.44863 6.45562C2.94763 6.58237 2.41063 6.65587 1.97638 6.65587C1.47838 6.65587 1.14163 6.56137 0.947377 6.36637C0.654127 6.07312 0.466627 5.67187 0.433627 5.26612C0.405877 4.92412 0.471877 4.41712 0.943627 3.94462C1.71013 3.17812 2.74063 2.58037 4.00813 2.16862C5.17363 1.78912 6.49963 1.58887 7.84363 1.58887C9.19663 1.58887 10.5339 1.79137 11.7106 2.17537C12.9894 2.59237 14.0304 3.19612 14.8036 3.97012C15.5911 4.75762 15.3961 5.80237 14.8126 6.38587C14.6199 6.57862 14.2876 6.67237 13.7971 6.67237ZM7.86163 3.36037C8.99188 3.36037 10.0426 3.54487 10.8211 3.87862C11.3559 4.10812 11.4459 4.58212 11.5111 4.92712C11.5501 5.13337 11.5839 5.31187 11.6911 5.41912C11.8801 5.60812 12.9706 5.92237 13.7979 5.92237C14.1586 5.92237 14.2726 5.86087 14.2869 5.85187C14.5194 5.61637 14.8104 5.03737 14.2741 4.50037C12.9204 3.14662 10.5166 2.33887 7.84363 2.33887C5.19238 2.33887 2.81188 3.13762 1.47388 4.47487C1.25788 4.69087 1.15888 4.93687 1.18063 5.20537C1.19938 5.43187 1.30813 5.66587 1.47313 5.83237C1.48738 5.84137 1.60288 5.90587 1.97563 5.90587C2.79913 5.90587 3.88138 5.59762 4.06738 5.41162C4.17313 5.30587 4.20613 5.12737 4.24438 4.92112C4.30888 4.57462 4.39663 4.09987 4.93213 3.87112C5.70163 3.54187 6.74188 3.36112 7.86088 3.36112L7.86163 3.36037Z"
        fill={color || "#000"}
      />
      <path
        d="M12.7514 13.5H3.00143C2.49818 13.5 2.03393 13.302 1.69418 12.9427C1.35443 12.5835 1.18268 12.1088 1.21043 11.6063C1.21343 11.5485 1.29893 10.1805 2.16293 8.802C2.67443 7.986 3.35243 7.33575 4.17743 6.86925C5.19818 6.2925 6.44243 6 7.87643 6C9.31042 6 10.5547 6.2925 11.5754 6.86925C12.4012 7.33575 13.0792 7.986 13.5899 8.802C14.4539 10.1813 14.5394 11.5493 14.5424 11.6063C14.5702 12.1088 14.3984 12.5835 14.0587 12.9427C13.7189 13.302 13.2547 13.5 12.7514 13.5ZM7.87643 6.75C5.53868 6.75 3.83543 7.566 2.81468 9.17475C2.04218 10.392 1.96043 11.6362 1.95968 11.649C1.94318 11.943 2.04293 12.2197 2.23943 12.4275C2.43593 12.6352 2.70668 12.75 3.00218 12.75H12.7522C13.0469 12.75 13.3177 12.6352 13.5149 12.4275C13.7122 12.2197 13.8112 11.943 13.7947 11.6483C13.7939 11.6363 13.7114 10.392 12.9397 9.17475C11.9182 7.566 10.2149 6.75 7.87792 6.75H7.87643Z"
        fill={color || "#000"}
      />
      <path
        d="M7.87646 12C6.63596 12 5.62646 10.9905 5.62646 9.75C5.62646 8.5095 6.63596 7.5 7.87646 7.5C9.11696 7.5 10.1265 8.5095 10.1265 9.75C10.1265 10.9905 9.11696 12 7.87646 12ZM7.87646 8.25C7.04921 8.25 6.37646 8.92275 6.37646 9.75C6.37646 10.5773 7.04921 11.25 7.87646 11.25C8.70371 11.25 9.37646 10.5773 9.37646 9.75C9.37646 8.92275 8.70371 8.25 7.87646 8.25Z"
        fill={color || "#000"}
      />
    </svg>
  );
});
