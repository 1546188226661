import React, {useEffect, useMemo, useState} from "react";
import {inject, observer} from "mobx-react";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import ContactsEdit from "./ContactForms/ContactsEdit";
import ContactCard from "components/ContactCard";
import MiniSelect from "components/selects/MiniSelect";
import EmptyData from "components/EmptyData";
import {LockValue} from "components/LockValue";
import {ModalContactMember} from "components/modals/ModalContactMember";
import {IconUsers} from "components/icons";
import {color} from "constants/color.consts";
import {useSavedFilters} from "helpers/userHooks";

const ContactPersons = inject("store")(
  observer(({store: {organization, auth}, edit, setEdit}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const {organizationId} = useParams();

    const [filters, setRegionFilter] = useSavedFilters({
      key: "organizationContactAvailableInFilter",
      initialValue: {availableIn: location?.state?.availableIn},
      storage: "session",
    });

    const {organizationDetail, organizationMembersSelectList} = organization;
    const {user, activeOrganization} = auth;

    const [isModalContactMemberId, setModalContactMemberId] = useState(null);
    const closeEdit = () => setEdit({...edit, contacts: false});

    const isOwnOrganization = useMemo(
      () => organizationId === activeOrganization.id && !!organizationDetail?.isActive,
      [organizationId, activeOrganization, organizationDetail?.isActive]
    );

    const initialContacts = useMemo(
      () => organizationDetail?.Contacts ?? [],
      [organizationDetail?.Contacts]
    );
    const regionSelectOptions = useMemo(
      () =>
        [{label: "SELECT_ALL_PROJECT_REGIONS", value: null}].concat(
          [
            ...new Set(
              initialContacts
                .filter((contact) => !!contact.rftsProjectAvailableIn)
                .map((contact) => contact.rftsProjectAvailableIn)
                .flat()
            ),
          ].map((item) => ({label: t(item), value: item}))
        ),
      [initialContacts, t]
    );
    const defaultRegion = useMemo(
      () =>
        regionSelectOptions.find((region) => region.value === filters?.availableIn)
          ?.value ?? null,
      [regionSelectOptions, filters]
    );
    const isRegionDropdown = useMemo(
      () => !isOwnOrganization && regionSelectOptions?.length > 1,
      [isOwnOrganization, regionSelectOptions]
    );
    const contacts = useMemo(
      () =>
        defaultRegion
          ? initialContacts?.filter((item) =>
              item.rftsProjectAvailableIn?.includes(defaultRegion)
            )
          : initialContacts,
      [initialContacts, defaultRegion]
    );

    const getContactInfo = async () =>
      await organization.getAnotherOrganizationContact(
        organizationDetail?.id,
        isModalContactMemberId
      );

    const handleSortRegions = (availableIn) =>
      setRegionFilter(availableIn ? {availableIn} : {});

    useEffect(() => {
      isOwnOrganization &&
        organization.getOrganizationMembers("SHOW_OUR_PROFILE_CONTACT_CARDS_USER");
    }, [organization, isOwnOrganization]);

    return edit.contacts ? (
      <ContactsEdit
        members={organizationMembersSelectList}
        contacts={contacts}
        closeEdit={closeEdit}
      />
    ) : (
      <PresentationCardWrapper
        title={t("CONTACT_PERSONS")}
        icon={<IconUsers />}
        edit={edit}
        setEdit={setEdit}
        customSelect={
          isRegionDropdown && (
            <MiniSelect
              list={regionSelectOptions}
              defaultValue={defaultRegion}
              addClass="ml-4"
              addDropdownClass="organization-page__contact-persons-dropdown"
              itemAsObject
              fullWidthDropdownLabel
              itemColor={color.green}
              onChange={handleSortRegions}
              tinyLabel
              boldLabel
            />
          )
        }
        titleToolTip={isRegionDropdown && "SELECT_PROJECT_REGIONS_TOOLTIP"}
        editName="contacts"
        editPermissions={["EDIT_OUR_PROFILE_MAIN_INFO"]}
        isActive={isOwnOrganization}
        addClass="__contact-person"
      >
        <LockValue
          canPermissions={["READ_OTHER_CONTACT_PERSONS"]}
          noHideOurInfo
          fullSubcribeButton
        >
          <div className="contact-person_wrapper webkit-msedge-chrome-nicer-scrollbar">
            {!!contacts.length ? (
              contacts.map((contact) => (
                <ContactCard
                  key={contact.id}
                  contact={contact}
                  currentUser={user}
                  selectedRegion={defaultRegion}
                  isActive={isOwnOrganization}
                  onClick={setModalContactMemberId}
                />
              ))
            ) : (
              <EmptyData />
            )}
            {isModalContactMemberId && (
              <ModalContactMember
                contactId={isModalContactMemberId}
                getData={getContactInfo}
                organizationName={organizationDetail.name}
                isActive={isOwnOrganization}
                onClose={() => setModalContactMemberId(null)}
              />
            )}
          </div>
        </LockValue>
      </PresentationCardWrapper>
    );
  })
);

export default ContactPersons;
