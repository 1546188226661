import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
// local
import {
  IconStandPhone,
  IconMailLetter,
  IconLicense,
  IconTags,
  IconBriefcase,
} from "components/icons";
import {Tag} from "components/Tag";
import {InitialsBadge} from "components/InitialsBadge";
import {getFullName} from "helpers/helper";
import {color} from "constants/color.consts";
import cn from "classnames";

const ContactCard = ({
  contact,
  maxWidthFitContent,
  blackContent,
  currentUser,
  selectedRegion,
  isActive,
  onClick,
  addClass,
}) => {
  const {t} = useTranslation();

  const iconColor = useMemo(
    () => (blackContent ? color.black : color.darkGray),
    [blackContent]
  );
  const regions = useMemo(() => contact?.rftsProjectAvailableIn, [contact]);
  const hasRegions = useMemo(() => !!regions?.length, [regions]);
  // const hasCurrentUser = useMemo(
  //   () => contact?.users?.map((user) => user?.id).includes(currentUser?.id),
  //   [contact, currentUser]
  // );

  return (
    <div
      className={cn("contact-person_item", {
        "__fit-content": !!maxWidthFitContent,
        "__black-text": !!blackContent,
        [addClass]: !!addClass,
      })}
      onClick={() => onClick && onClick(contact.UserId)}
    >
      <div className="contact-person_item__name">
        <InitialsBadge
          firstName={contact.User?.firstName}
          lastName={contact.User?.lastName}
          addClass="mr-2"
        />
        <span>{getFullName(contact.User?.firstName, contact.User?.lastName)}</span>
      </div>

      <div className="contact-person_item__mail">
        <IconMailLetter color={iconColor} />
        <span>{contact.email ?? "--"}</span>
      </div>
      <div className="contact-person_item__phone">
        <IconStandPhone color={iconColor} />
        <span>{contact.phone ?? contact.mobilePhone ?? "--"}</span>
      </div>
      {isActive && (
        <div className="contact-person_item__work-kinds">
          <IconLicense color={iconColor} />
          <span>{contact.position || "--"}</span>
        </div>
      )}
      {isActive && (
        <div className="contact-person_item__work-kinds">
          <IconTags color={iconColor} />
          <span>{contact.workKinds || "--"}</span>
        </div>
      )}
      {!isActive && (
        <div className="contact-person_item__work-info">
          <IconBriefcase color={iconColor} />
          {hasRegions ? (
            <Tag
              text={t(selectedRegion ?? regions[0])}
              color={color.green}
              bold
              noBackground
              miniSelectView
            />
          ) : (
            "--"
          )}

          {regions?.length > 1 && (
            <span className="ml-1 fz-10">{`+${regions.length - 1}`}</span>
          )}
          {/*         <InitialsBadge
            firstName={contact.User?.firstName}
            lastName={contact.User?.lastName}
            isCurrentUser={hasCurrentUser}
            addClass="mla"
          />
          <span className="ml-1 fz-10">{"+4"}</span>*/}
        </div>
      )}
    </div>
  );
};

export default ContactCard;
