import React, {memo} from "react";
import {useTranslation} from "react-i18next";
//local
import {CustomTooltip} from "components/CustomTooltip";
import {IconDeleteClose} from "components/icons/index";
import {getInitials} from "helpers/helper";
import {color} from "constants/color.consts";
import cn from "classnames";

export const InitialsBadge = memo(
  ({
    firstName,
    lastName,
    isCurrentUser,
    large,
    huge,
    needTooltip,
    needClearClick,
    customTooltip,
    onClick,
    addClass,
  }) => {
    const {t} = useTranslation();

    const Initials = () => (
      <span
        className={cn("initials-badge", {
          [addClass]: !!addClass,
          __large: !!large,
          __huge: !!huge,
          "current-user-initials": isCurrentUser,
          cpointer: !!onClick,
          "show-custom-icon": !!needClearClick,
        })}
        onClick={onClick}
      >
        {needClearClick && <IconDeleteClose color={color.red} addClass="icon-clear" />}
        {getInitials(firstName, lastName)}
      </span>
    );

    return needTooltip ? (
      <CustomTooltip
        text={`${firstName} ${lastName}\n${t(customTooltip)}`}
        centerText
        color="black"
      >
        <div className="df-row-center">
          <Initials />
        </div>
      </CustomTooltip>
    ) : (
      <Initials />
    );
  }
);
