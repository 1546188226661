export const verificationStatuses = {
  failed: "failed",
  complete: "complete",
  pending: "pending",
};

export const emptyPublicRequest = {
  id: null,
  readed: true,
  interested: null,
  emailSent: null,
  rejectMessage: null,
  SellerId: null,
  RequestId: null,
  UserAssigneeId: null,
  Offer: null,
  MessagesStat: {
    recentUpdatedMessageAt: null,
    unreadMessageCount: 0,
  },
};

export const sortPublicRFTSelectItems = [
  {label: "PUBLIC_RFT_SORT_SELECT_CLOSEDAT_ASC", value: "closedAt|ASC"},
  {label: "PUBLIC_RFT_SORT_SELECT_CLOSEDAT_DESC", value: "closedAt|DESC"},
  {label: "PUBLIC_RFT_SORT_SELECT_PUBLISHEDAT_DESC", value: "publishedAt|DESC"},
  {label: "PUBLIC_RFT_SORT_SELECT_PUBLISHEDAT_ASC", value: "publishedAt|ASC"},
];
