import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
//local
import {Tag} from "components/Tag";
import {LockValue} from "components/LockValue";
import {CustomTooltip} from "components/CustomTooltip";
import {TimeLeft} from "components/TimeLeft";
import {IconDotFilled} from "components/icons";
import {dateFormat} from "helpers/datetime";
import {toPublicRftId, toRequestToUsId} from "constants/routes.const";
import {color} from "constants/color.consts";
import cn from "classnames";

const PublicRfpsTableItem = ({publicRfp, organizationTurnover, showInfoAndClick}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const availableIn = publicRfp.ProjectItem?.Project?.availableIn;
  const readedRequest = useMemo(() => !!publicRfp.RequestReadedByMember, [publicRfp]);
  const countAvailableIn = availableIn?.length > 1 ? ` +${availableIn?.length - 1}` : "";
  const categories = [
    ...(publicRfp.consultingServices ?? []),
    ...(publicRfp.entrepreneurOffers ?? []),
    ...(publicRfp.entrepreneurWithin ?? []),
    ...(publicRfp.materialSupplierType ?? []),
  ];
  const countCategories = categories?.length > 1 ? ` +${categories?.length - 1}` : "";

  const iconDotFilled = <IconDotFilled color={color.red} />;
  const TooltipTag = ({data, count}) => (
    <CustomTooltip text={count ? data.map((item) => t(item)).join(", ") : null}>
      <div className="text-ellipsis">
        <Tag
          text={`${t(data[0])}${count}`}
          mediumSelectView
          noBackground
          color={readedRequest ? color.midGray : color.black}
        />
      </div>
    </CustomTooltip>
  );

  const handleRedirectOnClick = () => {
    const haveProposal = !!publicRfp.Proposals?.length;
    const requestId = haveProposal ? publicRfp.Proposals[0].id : null;
    const path = haveProposal ? toRequestToUsId(requestId) : toPublicRftId(publicRfp.id);
    navigate(path, {state: haveProposal ? {contractId: null} : null});
  };

  return (
    <div
      className={cn("requests-card_body", {"mid-gray": readedRequest})}
      onClick={() => showInfoAndClick && handleRedirectOnClick()}
    >
      <div className="requests-card_body__column __wrapper __category">
        {!!categories?.length ? (
          <TooltipTag data={categories} count={countCategories} />
        ) : (
          <Tag
            text={t("PUBLIC_RFPS_PAGE_TABLE_NO_CATEGORY")}
            mediumSelectView
            color={color.midGray}
            noBackground
          />
        )}
      </div>
      <div className="requests-card_body__column __wrapper __region">
        {!!availableIn?.length ? (
          <TooltipTag data={availableIn} count={countAvailableIn} />
        ) : (
          <Tag
            text={t("PUBLIC_RFPS_PAGE_TABLE_NO_REGION")}
            mediumSelectView
            color={color.midGray}
            noBackground
          />
        )}
      </div>
      <div className="requests-card_body__column __wrapper __published-at">
        {publicRfp.publishedAt ? dateFormat(publicRfp.publishedAt) : "-"}
      </div>
      <div className="requests-card_body__column __wrapper __time-left">
        <TimeLeft date={publicRfp.closedAt} hasShortLabels />
      </div>
      <div className="requests-card_body__column __wrapper __customers">
        <LockValue
          canPermissions={["READ_OTHER_OPEN_RFT"]}
          customLockIcon={iconDotFilled}
        >
          {publicRfp.Buyer?.name ?? "-"}
        </LockValue>
      </div>
      <div className="requests-card_body__column __wrapper __project-name">
        <LockValue
          canPermissions={["READ_OTHER_OPEN_RFT"]}
          customLockIcon={iconDotFilled}
        >
          {publicRfp.ProjectItem?.Project?.name}
        </LockValue>
      </div>
      <div className="requests-card_body__column __wrapper __rfp-name">
        <LockValue
          canPermissions={["READ_OTHER_OPEN_RFT"]}
          customLockIcon={iconDotFilled}
        >
          {publicRfp.name.slice(0, 100) ?? "-"}
        </LockValue>
      </div>
    </div>
  );
};

export default PublicRfpsTableItem;
